<template>
<body class="menu-position-side menu-side-left full-screen with-content-panel">
    <div ui-view="" autoscroll="true" style="min-height: 100%;" class="ng-scope">
        <div class="all-wrapper menu-side with-pattern ng-scope" ng-class="{'d-none' : !emailSent}" style="padding: 0px !important; background: #09b585; background-size: 100% 100%;">
            <div class="auth-box-w" style="margin-top: 100px; background-color: #f7f7f7;">
                <div class="logo-w" style="padding:5%">
                    <a ui-sref="home.main" href="/">
                        <img alt="" src="/assets/img/prohibit.jpg" height="100px" ng-if="!whiteLabeled" class="ng-scope">
                    </a>
                </div>
                <h4 class="text-center">Link has expired
                </h4>
                <form name="login_form" novalidate="" class="ng-pristine ng-valid">
                    <p>This link has been expired!</p>
                    <!-- <p>Make sure to check your junk or spam folder if you did not receive the email in your inbox.</p> -->
                    <br>
                    <div class="row">
                        <div class="col">
                            <button class="btn btn-primary" type="button" ui-sref="login" v-on:click="gotoLogin()">
                                Go to Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</body>
</template>

<script>
import * as External from '@/utils/External.js'
export default {
    name: "CodeExpired",
    mounted() {
        External.head_link("/assets/css/tt/styleRegister.css");
        External.head_link("/assets/css/tt/themeRegister.css");
        External.head_link("/assets/css/tt/mainLogin.css");
        External.head_link("/assets/css/tt/style(1)Login.css");
        External.head_link("/assets/css/tt/rocket.css");
    },
    destroyed() {
        External.del_link("/assets/css/tt/styleRegister.css");
        External.del_link("/assets/css/tt/themeRegister.css");
        External.del_link("/assets/css/tt/mainLogin.css");
        External.del_link("/assets/css/tt/style(1)Login.css");
        External.del_link("/assets/css/tt/rocket.css");
    },
    data() {
        return {};
    },
    methods: {
        gotoLogin() {
            this.$router.push({
                path: "/login"
            });
        }
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '/assets/css/company.css';
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");

.pagination {
    justify-content: center;
    text-align: center;
}
</style>
